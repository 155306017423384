export const LIST_PERMISSION = {
  DASHBOARD_VIEW: "DASHBOARD_VIEW",

  MEMBER_GROUP_VIEW: "MEMBER_GROUP_VIEW",
  MEMBER_GROUP_EXPORT: "MEMBER_GROUP_EXPORT",
  MEMBER_GROUP_ADD: "MEMBER_GROUP_ADD",
  MEMBER_GROUP_UPDATE: "MEMBER_GROUP_UPDATE",

  MEMBER_VIEW: "MEMBER_VIEW",
  MEMBER_EXPORT: "MEMBER_EXPORT",
  MEMBER_LOGOUT: "MEMBER_LOGOUT",
  MEMBER_UPDATE: "MEMBER_UPDATE",

  USER_GROUP_VIEW: "USER_GROUP_VIEW",
  USER_GROUP_EXPORT: "USER_GROUP_EXPORT",
  USER_GROUP_ADD: "USER_GROUP_ADD",
  USER_GROUP_UPDATE: "USER_GROUP_UPDATE",

  USER_VIEW: "USER_VIEW",
  USER_EXPORT: "USER_EXPORT",
  USER_ADD: "USER_ADD",
  USER_UPDATE: "USER_UPDATE",
  USER_RESET_PASSWORD: "USER_RESET_PASSWORD",

  PRODUCT_GROUP_VIEW: "PRODUCT_GROUP_VIEW",
  PRODUCT_GROUP_EXPORT: "PRODUCT_GROUP_EXPORT",
  PRODUCT_GROUP_ADD: "PRODUCT_GROUP_ADD",
  PRODUCT_GROUP_UPDATE: "PRODUCT_GROUP_UPDATE",

  PRODUCT_VIEW: "PRODUCT_VIEW",
  PRODUCT_EXPORT: "PRODUCT_EXPORT",
  PRODUCT_ADD: "PRODUCT_ADD",
  PRODUCT_UPDATE: "PRODUCT_UPDATE",

  VAT_DETAIL: "VAT_DETAIL",
  VAT_UPDATE: "VAT_UPDATE",

  VOUCHER_VIEW: "VOUCHER_VIEW",
  VOUCHER_EXPORT: "VOUCHER_EXPORT",
  VOUCHER_ADD: "VOUCHER_ADD",
  VOUCHER_UPDATE: "VOUCHER_UPDATE",

  ORDER_VIEW: "ORDER_VIEW",
  ORDER_EXPORT: "ORDER_EXPORT",
  ORDER_ADD: "ORDER_ADD",
  ORDER_UPDATE: "ORDER_UPDATE",
  ORDER_CANCEL: "ORDER_CANCEL",
  ORDER_REFUND: "ORDER_REFUND",
  ORDER_RESEND: "ORDER_RESEND",

  DELIVERY_FEE_VIEW: "DELIVERY_FEE_VIEW",
  DELIVERY_FEE_EXPORT: "DELIVERY_FEE_EXPORT",
  DELIVERY_FEE_ADD: "DELIVERY_FEE_ADD",
  DELIVERY_FEE_UPDATE: "DELIVERY_FEE_UPDATE",

  PROMOTION_VIEW: "PROMOTION_VIEW",
  PROMOTION_EXPORT: "PROMOTION_EXPORT",
  PROMOTION_ADD: "PROMOTION_ADD",
  PROMOTION_UPDATE: "PROMOTION_UPDATE",
  PROMOTION_APPROVE_DENY: "PROMOTION_APPROVE_DENY",
  PROMOTION_PAUSE: "PROMOTION_PAUSE",

  SURVEY_SET_OF_QUESTION_VIEW: "SURVEY_SET_OF_QUESTION_VIEW",
  SURVEY_SET_OF_QUESTION_EXPORT: "SURVEY_SET_OF_QUESTION_EXPORT",
  SURVEY_SET_OF_QUESTION_ADD: "SURVEY_SET_OF_QUESTION_ADD",
  SURVEY_SET_OF_QUESTION_UPDATE: "SURVEY_SET_OF_QUESTION_UPDATE",
  SURVEY_SET_OF_QUESTION_RESULT_EXPORT: "SURVEY_SET_OF_QUESTION_RESULT_EXPORT",

  SURVEY_QUESTION_VIEW: "SURVEY_QUESTION_VIEW",
  SURVEY_QUESTION_EXPORT: "SURVEY_QUESTION_EXPORT",
  SURVEY_QUESTION_ADD: "SURVEY_QUESTION_ADD",
  SURVEY_QUESTION_UPDATE: "SURVEY_QUESTION_UPDATE",

  GUESTS_VIDEO_VIEW: "GUESTS_VIDEO_VIEW",
  GUESTS_VIDEO_EXPORT: "GUESTS_VIDEO_EXPORT",
  GUESTS_VIDEO_ADD: "GUESTS_VIDEO_ADD",
  GUESTS_VIDEO_UPDATE: "GUESTS_VIDEO_UPDATE",

  GUESTS_SET_OF_QUESTION_VIEW: "GUESTS_SET_OF_QUESTION_VIEW",
  GUESTS_SET_OF_QUESTION_EXPORT: "GUESTS_SET_OF_QUESTION_EXPORT",
  GUESTS_SET_OF_QUESTION_ADD: "GUESTS_SET_OF_QUESTION_ADD",
  GUESTS_SET_OF_QUESTION_UPDATE: "GUESTS_SET_OF_QUESTION_UPDATE",

  GUESTS_QUESTION_VIEW: "GUESTS_QUESTION_VIEW",
  GUESTS_QUESTION_EXPORT: "GUESTS_QUESTION_EXPORT",
  GUESTS_QUESTION_ADD: "GUESTS_QUESTION_ADD",
  GUESTS_QUESTION_UPDATE: "GUESTS_QUESTION_UPDATE",

  GUESTS_PRODUCT_VIEW: "GUESTS_PRODUCT_VIEW",
  GUESTS_PRODUCT_EXPORT: "GUESTS_PRODUCT_EXPORT",
  GUESTS_PRODUCT_ADD: "GUESTS_PRODUCT_ADD",
  GUESTS_PRODUCT_UPDATE: "GUESTS_PRODUCT_UPDATE",

  MEDITATION_POST_VIEW: "MEDITATION_POST_VIEW",
  MEDITATION_POST_EXPORT: "MEDITATION_POST_EXPORT",
  MEDITATION_POST_ADD: "MEDITATION_POST_ADD",
  MEDITATION_POST_UPDATE: "MEDITATION_POST_UPDATE",

  BMI_VIEW: "BMI_VIEW",
  BMI_EXPORT: "BMI_EXPORT",
  BMI_ADD: "BMI_ADD",
  BMI_UPDATE: "BMI_UPDATE",

  BMI_RESULT_VIEW: "BMI_RESULT_VIEW",
  BMI_RESULT_EXPORT: "BMI_RESULT_EXPORT",

  DISCOVERY_MEDITATION_POST_VIEW: "DISCOVERY_MEDITATION_POST_VIEW",
  DISCOVERY_MEDITATION_POST_EXPORT: "DISCOVERY_MEDITATION_POST_EXPORT",
  DISCOVERY_MEDITATION_POST_ADD: "DISCOVERY_MEDITATION_POST_ADD",
  DISCOVERY_MEDITATION_POST_UPDATE: "DISCOVERY_MEDITATION_POST_UPDATE",

  EAT_HEALTHY_POST_VIEW: "EAT_HEALTHY_POST_VIEW",
  EAT_HEALTHY_POST_EXPORT: "EAT_HEALTHY_POST_EXPORT",
  EAT_HEALTHY_POST_ADD: "EAT_HEALTHY_POST_ADD",
  EAT_HEALTHY_POST_UPDATE: "EAT_HEALTHY_POST_UPDATE",

  CHANGE_LIFE_BOOK_VIEW: "CHANGE_LIFE_BOOK_VIEW",
  CHANGE_LIFE_BOOK_EXPORT: "CHANGE_LIFE_BOOK_EXPORT",
  CHANGE_LIFE_BOOK_ADD: "CHANGE_LIFE_BOOK_ADD",
  CHANGE_LIFE_BOOK_UPDATE: "CHANGE_LIFE_BOOK_UPDATE",

  BUILD_BODY_CATEGORY_VIEW: "BUILD_BODY_CATEGORY_VIEW",
  BUILD_BODY_CATEGORY_EXPORT: "BUILD_BODY_CATEGORY_EXPORT",
  BUILD_BODY_CATEGORY_ADD: "BUILD_BODY_CATEGORY_ADD",
  BUILD_BODY_CATEGORY_UPDATE: "BUILD_BODY_CATEGORY_UPDATE",

  BUILD_BODY_POST_VIEW: "BUILD_BODY_POST_VIEW",
  BUILD_BODY_POST_EXPORT: "BUILD_BODY_POST_EXPORT",
  BUILD_BODY_POST_ADD: "BUILD_BODY_POST_ADD",
  BUILD_BODY_POST_UPDATE: "BUILD_BODY_POST_UPDATE",

  BUILD_SOUL_POST_VIEW: "BUILD_SOUL_POST_VIEW",
  BUILD_SOUL_POST_EXPORT: "BUILD_SOUL_POST_EXPORT",
  BUILD_SOUL_POST_ADD: "BUILD_SOUL_POST_ADD",
  BUILD_SOUL_POST_UPDATE: "BUILD_SOUL_POST_UPDATE",

  MENTAL_TRAINING_POST_VIEW: "MENTAL_TRAINING_POST_VIEW",
  MENTAL_TRAINING_EXPORT: "MENTAL_TRAINING_EXPORT",
  MENTAL_TRAINING_POST_CREATE: "MENTAL_TRAINING_POST_CREATE",
  MENTAL_TRAINING_POST_UPDATE: "MENTAL_TRAINING_POST_UPDATE",
  MENTAL_TRAINING_POST_RESULT_VIEW: "MENTAL_TRAINING_POST_RESULT_VIEW",
  MENTAL_TRAINING_POST_RESULT_EXPORT: "MENTAL_TRAINING_POST_RESULT_EXPORT",

  BASIC_BOOK_VIEW: "BASIC_BOOK_VIEW",
  BASIC_BOOK_EXPORT: "BASIC_BOOK_EXPORT",
  BASIC_BOOK_ADD: "BASIC_BOOK_ADD",
  BASIC_BOOK_UPDATE: "BASIC_BOOK_UPDATE",

  OPEN_BOOK_VIEW: "OPEN_BOOK_VIEW",
  OPEN_BOOK_EXPORT: "OPEN_BOOK_EXPORT",
  OPEN_BOOK_ADD: "OPEN_BOOK_ADD",
  OPEN_BOOK_UPDATE: "OPEN_BOOK_UPDATE",

  BASIC_FILM_VIEW: "BASIC_FILM_VIEW",
  BASIC_FILM_EXPORT: "BASIC_FILM_EXPORT",
  BASIC_FILM_ADD: "BASIC_FILM_ADD",
  BASIC_FILM_UPDATE: "BASIC_FILM_UPDATE",

  BARTENDING_POST_VIEW: "BARTENDING_POST_VIEW",
  BARTENDING_POST_EXPORT: "BARTENDING_POST_EXPORT",
  BARTENDING_POST_ADD: "BARTENDING_POST_ADD",
  BARTENDING_POST_UPDATE: "BARTENDING_POST_UPDATE",

  OPEN_MUSIC_POST_VIEW: "OPEN_MUSIC_POST_VIEW",
  OPEN_MUSIC_POST_EXPORT: "OPEN_MUSIC_POST_EXPORT",
  OPEN_MUSIC_POST_ADD: "OPEN_MUSIC_POST_ADD",
  OPEN_MUSIC_POST_UPDATE: "OPEN_MUSIC_POST_UPDATE",

  OPEN_OTHER_FILE_POST_VIEW: "OPEN_OTHER_FILE_POST_VIEW",
  OPEN_OTHER_FILE_POST_EXPORT: "OPEN_OTHER_FILE_POST_EXPORT",
  OPEN_OTHER_FILE_POST_ADD: "OPEN_OTHER_FILE_POST_ADD",
  OPEN_OTHER_FILE_POST_UPDATE: "OPEN_OTHER_FILE_POST_UPDATE",

  HEAL_MEDITATION_EXPERT_VIEW: "HEAL_MEDITATION_EXPERT_VIEW",
  HEAL_MEDITATION_EXPERT_EXPORT: "HEAL_MEDITATION_EXPERT_EXPORT",
  HEAL_MEDITATION_EXPERT_ADD: "HEAL_MEDITATION_EXPERT_ADD",
  HEAL_MEDITATION_EXPERT_UPDATE: "HEAL_MEDITATION_EXPERT_UPDATE",

  HEAL_MEDITATION_POST_VIEW: "HEAL_MEDITATION_POST_VIEW",
  HEAL_MEDITATION_POST_EXPORT: "HEAL_MEDITATION_POST_EXPORT",
  HEAL_MEDITATION_POST_ADD: "HEAL_MEDITATION_POST_ADD",
  HEAL_MEDITATION_POST_UPDATE: "HEAL_MEDITATION_POST_UPDATE",

  HEAL_MEDITATION_TALKSHOW_VIEW: "HEAL_MEDITATION_TALKSHOW_VIEW",
  HEAL_MEDITATION_TALKSHOW_EXPORT: "HEAL_MEDITATION_TALKSHOW_EXPORT",
  HEAL_MEDITATION_TALKSHOW_ADD: "HEAL_MEDITATION_TALKSHOW_ADD",
  HEAL_MEDITATION_TALKSHOW_UPDATE: "HEAL_MEDITATION_TALKSHOW_UPDATE",
  HEAL_MEDITATION_TALKSHOW_OPEN_CLOSE: "HEAL_MEDITATION_TALKSHOW_OPEN/CLOSE",
  HEAL_MEDITATION_TALKSHOW_REMOVE_COMMENT:
    "HEAL_MEDITATION_TALKSHOW_REMOVE_COMMENT",
  HEAL_MEDITATION_TALKSHOW_APPROVE_QUESTION:
    "HEAL_MEDITATION_TALKSHOW_APPROVE_QUESTION",
  HEAL_MEDITATION_TALKSHOW_APPROVE_CANCEL:
    "HEAL_MEDITATION_TALKSHOW_APPROVE/CANCEL",
  HEAL_MEDITATION_TALKSHOW_OPEN_CLOSE_COMMENT:
    "HEAL_MEDITATION_TALKSHOW_OPEN/CLOSE_COMMENT",

  HEAL_MOVING_MEDITATION_EXPERT_VIEW: "HEAL_MOVING_MEDITATION_EXPERT_VIEW",
  HEAL_MOVING_MEDITATION_EXPERT_EXPORT: "HEAL_MOVING_MEDITATION_EXPERT_EXPORT",
  HEAL_MOVING_MEDITATION_EXPERT_ADD: "HEAL_MOVING_MEDITATION_EXPERT_ADD",
  HEAL_MOVING_MEDITATION_EXPERT_UPDATE: "HEAL_MOVING_MEDITATION_EXPERT_UPDATE",

  HEAL_MOVING_MEDITATION_POST_VIEW: "HEAL_MOVING_MEDITATION_POST_VIEW",
  HEAL_MOVING_MEDITATION_POST_EXPORT: "HEAL_MOVING_MEDITATION_POST_EXPORT",
  HEAL_MOVING_MEDITATION_POST_ADD: "HEAL_MOVING_MEDITATION_POST_ADD",
  HEAL_MOVING_MEDITATION_POST_UPDATE: "HEAL_MOVING_MEDITATION_POST_UPDATE",

  HEAL_MOVING_MEDITATION_TALKSHOW_VIEW: "HEAL_MOVING_MEDITATION_TALKSHOW_VIEW",
  HEAL_MOVING_MEDITATION_TALKSHOW_EXPORT:
    "HEAL_MOVING_MEDITATION_TALKSHOW_EXPORT",
  HEAL_MOVING_MEDITATION_TALKSHOW_ADD: "HEAL_MOVING_MEDITATION_TALKSHOW_ADD",
  HEAL_MOVING_MEDITATION_TALKSHOW_UPDATE:
    "HEAL_MOVING_MEDITATION_TALKSHOW_UPDATE",
  HEAL_MOVING_MEDITATION_TALKSHOW_OPEN_CLOSE:
    "HEAL_MOVING_MEDITATION_TALKSHOW_OPEN/CLOSE",
  HEAL_MOVING_MEDITATION_TALKSHOW_REMOVE_COMMENT:
    "HEAL_MOVING_MEDITATION_TALKSHOW_REMOVE_COMMENT",
  HEAL_MOVING_MEDITATION_TALKSHOW_APPROVE_QUESTION:
    "HEAL_MOVING_MEDITATION_TALKSHOW_APPROVE_QUESTION",
  HEAL_MOVING_MEDITATION_TALKSHOW_APPROVE_CANCEL:
    "HEAL_MOVING_MEDITATION_TALKSHOW_APPROVE/CANCEL",
  HEAL_MOVING_MEDITATION_TALKSHOW_OPEN_CLOSE_COMMENT:
    "HEAL_MOVING_MEDITATION_TALKSHOW_OPEN/CLOSE_COMMENT",

  HEAL_YOGA_EXPERT_VIEW: "HEAL_YOGA_EXPERT_VIEW",
  HEAL_YOGA_EXPERT_EXPORT: "HEAL_YOGA_EXPERT_EXPORT",
  HEAL_YOGA_EXPERT_ADD: "HEAL_YOGA_EXPERT_ADD",
  HEAL_YOGA_EXPERT_UPDATE: "HEAL_YOGA_EXPERT_UPDATE",

  HEAL_YOGA_POST_VIEW: "HEAL_YOGA_POST_VIEW",
  HEAL_YOGA_POST_EXPORT: "HEAL_YOGA_POST_EXPORT",
  HEAL_YOGA_POST_ADD: "HEAL_YOGA_POST_ADD",
  HEAL_YOGA_POST_UPDATE: "HEAL_YOGA_POST_UPDATE",

  HEAL_YOGA_TALKSHOW_VIEW: "HEAL_YOGA_TALKSHOW_VIEW",
  HEAL_YOGA_TALKSHOW_EXPORT: "HEAL_YOGA_TALKSHOW_EXPORT",
  HEAL_YOGA_TALKSHOW_ADD: "HEAL_YOGA_TALKSHOW_ADD",
  HEAL_YOGA_TALKSHOW_UPDATE: "HEAL_YOGA_TALKSHOW_UPDATE",
  HEAL_YOGA_TALKSHOW_OPEN_CLOSE: "HEAL_YOGA_TALKSHOW_OPEN/CLOSE",
  HEAL_YOGA_TALKSHOW_REMOVE_COMMENT: "HEAL_YOGA_TALKSHOW_REMOVE_COMMENT",
  HEAL_YOGA_TALKSHOW_APPROVE_QUESTION: "HEAL_YOGA_TALKSHOW_APPROVE_QUESTION",
  HEAL_YOGA_TALKSHOW_APPROVE_CANCEL: "HEAL_YOGA_TALKSHOW_APPROVE/CANCEL",
  HEAL_YOGA_TALKSHOW_OPEN_CLOSE_COMMENT:
    "HEAL_YOGA_TALKSHOW_OPEN/CLOSE_COMMENT",

  HEAL_THERAPY_EXPERT_VIEW: "HEAL_THERAPY_EXPERT_VIEW",
  HEAL_THERAPY_EXPERT_EXPORT: "HEAL_THERAPY_EXPERT_EXPORT",
  HEAL_THERAPY_EXPERT_ADD: "HEAL_THERAPY_EXPERT_ADD",
  HEAL_THERAPY_EXPERT_UPDATE: "HEAL_THERAPY_EXPERT_UPDATE",

  HEAL_THERAPY_POST_VIEW: "HEAL_THERAPY_POST_VIEW",
  HEAL_THERAPY_POST_EXPORT: "HEAL_THERAPY_POST_EXPORT",
  HEAL_THERAPY_POST_ADD: "HEAL_THERAPY_POST_ADD",
  HEAL_THERAPY_POST_UPDATE: "HEAL_THERAPY_POST_UPDATE",

  HEAL_THERAPY_TALKSHOW_VIEW: "HEAL_THERAPY_TALKSHOW_VIEW",
  HEAL_THERAPY_TALKSHOW_EXPORT: "HEAL_THERAPY_TALKSHOW_EXPORT",
  HEAL_THERAPY_TALKSHOW_ADD: "HEAL_THERAPY_TALKSHOW_ADD",
  HEAL_THERAPY_TALKSHOW_UPDATE: "HEAL_THERAPY_TALKSHOW_UPDATE",
  HEAL_THERAPY_TALKSHOW_OPEN_CLOSE: "HEAL_THERAPY_TALKSHOW_OPEN/CLOSE",
  HEAL_THERAPY_TALKSHOW_REMOVE_COMMENT: "HEAL_THERAPY_TALKSHOW_REMOVE_COMMENT",
  HEAL_THERAPY_TALKSHOW_APPROVE_QUESTION:
    "HEAL_THERAPY_TALKSHOW_APPROVE_QUESTION",
  HEAL_THERAPY_TALKSHOW_APPROVE_CANCEL: "HEAL_THERAPY_TALKSHOW_APPROVE/CANCEL",
  HEAL_THERAPY_TALKSHOW_OPEN_CLOSE_COMMENT:
    "HEAL_THERAPY_TALKSHOW_OPEN/CLOSE_COMMENT",

  HEAL_GUIDE_CONNECT_POST_VIEW: "HEAL_GUIDE_CONNECT_POST_VIEW",
  HEAL_GUIDE_CONNECT_POST_EXPORT: "HEAL_GUIDE_CONNECT_POST_EXPORT",
  HEAL_GUIDE_CONNECT_POST_ADD: "HEAL_GUIDE_CONNECT_POST_ADD",
  HEAL_GUIDE_CONNECT_POST_UPDATE: "HEAL_GUIDE_CONNECT_POST_UPDATE",

  NEWS_NOTIFICATION_POST_VIEW: "NEWS_NOTIFICATION_POST_VIEW",
  NEWS_NOTIFICATION_POST_EXPORT: "NEWS_NOTIFICATION_POST_EXPORT",
  NEWS_NOTIFICATION_POST_ADD: "NEWS_NOTIFICATION_POST_ADD",
  NEWS_NOTIFICATION_POST_UPDATE: "NEWS_NOTIFICATION_POST_UPDATE",

  NEWS_EVENT_POST_VIEW: "NEWS_EVENT_POST_VIEW",
  NEWS_EVENT_POST_EXPORT: "NEWS_EVENT_POST_EXPORT",
  NEWS_EVENT_POST_ADD: "NEWS_EVENT_POST_ADD",
  NEWS_EVENT_POST_UPDATE: "NEWS_EVENT_POST_UPDATE",
  NEWS_EVENT_POST_OPEN_CLOSE: "NEWS_EVENT_POST_OPEN/CLOSE",
  NEWS_EVENT_POST_REMOVE_COMMENT: "NEWS_EVENT_POST_REMOVE_COMMENT",
  NEWS_EVENT_POST_APPROVE_QUESTION: "NEWS_EVENT_POST_APPROVE_QUESTION",
  NEWS_EVENT_POST_APPROVE_CANCEL: "NEWS_EVENT_POST_APPROVE/CANCEL",

  NEWS_INTRODUCE_PRODUCT_POST_VIEW: "NEWS_INTRODUCE_PRODUCT_POST_VIEW",
  NEWS_INTRODUCE_PRODUCT_POST_EXPORT: "NEWS_INTRODUCE_PRODUCT_POST_EXPORT",
  NEWS_INTRODUCE_PRODUCT_POST_ADD: "NEWS_INTRODUCE_PRODUCT_POST_ADD",
  NEWS_INTRODUCE_PRODUCT_POST_UPDATE: "NEWS_INTRODUCE_PRODUCT_POST_UPDATE",

  TRIP_LEVEL_VIEW: "TRIP_LEVEL_VIEW",

  TRIP_MUSIC_VIEW: "TRIP_MUSIC_VIEW",
  TRIP_MUSIC_EXPORT: "TRIP_MUSIC_EXPORT",
  TRIP_MUSIC_ADD: "TRIP_MUSIC_ADD",
  TRIP_MUSIC_UPDATE: "TRIP_MUSIC_UPDATE",

  TRIP_BOOK_VIEW: "TRIP_BOOK_VIEW",
  TRIP_BOOK_EXPORT: "TRIP_BOOK_EXPORT",
  TRIP_BOOK_ADD: "TRIP_BOOK_ADD",
  TRIP_BOOK_UPDATE: "TRIP_BOOK_UPDATE",

  TRIP_VIDEO_VIEW: "TRIP_VIDEO_VIEW",
  TRIP_VIDEO_EXPORT: "TRIP_VIDEO_EXPORT",
  TRIP_VIDEO_ADD: "TRIP_VIDEO_ADD",
  TRIP_VIDEO_UPDATE: "TRIP_VIDEO_UPDATE",

  COMMUNITY_EVENT_VIEW: "COMMUNITY_EVENT_VIEW",
  COMMUNITY_EVENT_EXPORT: "COMMUNITY_EVENT_EXPORT",
  COMMUNITY_EVENT_ADD: "COMMUNITY_EVENT_ADD",
  COMMUNITY_EVENT_UPDATE: "COMMUNITY_EVENT_UPDATE",
  COMMUNITY_EVENT_OPEN_CLOSE: "COMMUNITY_EVENT_OPEN/CLOSE",
  COMMUNITY_EVENT_REMOVE_COMMENT: "COMMUNITY_EVENT_REMOVE_COMMENT",
  COMMUNITY_EVENT_APPROVE_QUESTION: "COMMUNITY_EVENT_APPROVE_QUESTION",
  COMMUNITY_EVENT_APPROVE_CANCEL: "COMMUNITY_EVENT_APPROVE/CANCEL",
  COMMUNITY_EVENT_OPEN_CLOSE_COMMENT: "COMMUNITY_EVENT_OPEN/CLOSE_COMMENT",

  COMMUNITY_SHARE_VIEW: "COMMUNITY_SHARE_VIEW",
  COMMUNITY_SHARE_EXPORT: "COMMUNITY_SHARE_EXPORT",
  COMMUNITY_SHARE_ADD: "COMMUNITY_SHARE_ADD",
  COMMUNITY_SHARE_UPDATE: "COMMUNITY_SHARE_UPDATE",

  COMMUNITY_CONNECT_VIEW: "COMMUNITY_CONNECT_VIEW",
  COMMUNITY_CONNECT_EXPORT: "COMMUNITY_CONNECT_EXPORT",
  COMMUNITY_CONNECT_ADD: "COMMUNITY_CONNECT_ADD",
  COMMUNITY_CONNECT_UPDATE: "COMMUNITY_CONNECT_UPDATE",

  GAME_INFO_VIEW: "GAME_INFO_VIEW",
  GAME_INFO_EXPORT: "GAME_INFO_EXPORT",
  GAME_INFO_UPDATE: "GAME_INFO_UPDATE",

  GAME_QUESTION_VIEW: "GAME_QUESTION_VIEW",
  GAME_QUESTION_EXPORT: "GAME_QUESTION_EXPORT",
  GAME_QUESTION_ADD: "GAME_QUESTION_ADD",
  GAME_QUESTION_UPDATE: "GAME_QUESTION_UPDATE",

  GAME_RESULT_VIEW: "GAME_RESULT_VIEW",
  GAME_RESULT_EXPORT: "GAME_RESULT_EXPORT",

  LIBRARY_VIDEO_VIEW: "LIBRARY_VIDEO_VIEW",
  LIBRARY_VIDEO_EXPORT: "LIBRARY_VIDEO_EXPORT",
  LIBRARY_VIDEO_ADD: "LIBRARY_VIDEO_ADD",
  LIBRARY_VIDEO_UPDATE: "LIBRARY_VIDEO_UPDATE",

  LIBRARY_AUDIO_VIEW: "LIBRARY_AUDIO_VIEW",
  LIBRARY_AUDIO_EXPORT: "LIBRARY_AUDIO_EXPORT",
  LIBRARY_AUDIO_ADD: "LIBRARY_AUDIO_ADD",
  LIBRARY_AUDIO_UPDATE: "LIBRARY_AUDIO_UPDATE",

  LIBRARY_BOOK_VIEW: "LIBRARY_BOOK_VIEW",
  LIBRARY_BOOK_EXPORT: "LIBRARY_BOOK_EXPORT",
  LIBRARY_BOOK_ADD: "LIBRARY_BOOK_ADD",
  LIBRARY_BOOK_UPDATE: "LIBRARY_BOOK_UPDATE",

  LIBRARY_OTHER_VIEW: "LIBRARY_OTHER_VIEW",
  LIBRARY_OTHER_EXPORT: "LIBRARY_OTHER_EXPORT",
  LIBRARY_OTHER_ADD: "LIBRARY_OTHER_ADD",
  LIBRARY_OTHER_UPDATE: "LIBRARY_OTHER_UPDATE",

  NOTIFICATION_VIEW: "NOTIFICATION_VIEW",
  NOTIFICATION_EXPORT: "NOTIFICATION_EXPORT",
  NOTIFICATION_ADD: "NOTIFICATION_ADD",
  NOTIFICATION_UPDATE: "NOTIFICATION_UPDATE",
  NOTIFICATION_APPROVE_CANCEL: "NOTIFICATION_APPROVE/CANCEL",

  VERSION_VIEW: "VERSION_VIEW",
  VERSION_UPDATE: "VERSION_UPDATE",

  STICKER_VIEW: "STICKER_VIEW",
  STICKER_ADD: "STICKER_ADD",
  STICKER_UPDATE: "STICKER_UPDATE",
};
